
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'Loader',
    props: {
      loaderClass: {
        type: String,
        default: 'absolute'
      },
      loaderStyle: {
        type: [
          Boolean,
          String
        ],
        default: false
      },
      spinnerClass: {
        type: String,
        default: ''
      },
      text: {
        type: [
          Boolean,
          String
        ],
        default: false
      }
    },
    setup () {
      const loaderType = 'default' // | 'spinner-message' | 'spinner-logo'

      return {
        loaderType
      }
    }
  })
