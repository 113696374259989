import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text mr-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["content-loader", _ctx.loaderClass]),
    style: _normalizeStyle(_ctx.loaderStyle)
  }, [
    (_ctx.text)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.text), 1))
      : _createCommentVNode("", true),
    _createElementVNode("span", {
      class: _normalizeClass(["spinner-border spinner-border-sm align-middle ms-2 text-primary", _ctx.spinnerClass || 'spinner-primary'])
    }, null, 2)
  ], 6))
}